<template>
  <date-picker
      id="expires_at"
      v-if="expireTime"
      v-model="value.expires_at"
      :key="0"
      format="dddd[,] DD [de] MMMM"
      value-type="YYYY-MM-DD HH:mm:ss"
      placeholder="Selecione uma data"
      :clearable="true"
      :disabled-date="d => d.getTime() < expireTime"
      :lang="{
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
        },
      }"
      class="full"
  />
</template>
<script>
import {mapState, mapActions} from 'vuex'
import {getCurrentClinic} from "@/utils/localStorageManager"
import {holidays} from "@/modules/financial/utils/financialHelper"

export default {
  name: "BudgetExpiresAt",
  props: {
    value: Object
  },
  computed: {
    ...mapState({
      configs: state => state.sellingPanel.configs
    })
  },
  data() {
    return {
      expireDays: 2,
      expireTime: null
    }
  },
  mounted() {
    if(! this.configs) {
      const clinicId = this.value?.clinic_id?.id || getCurrentClinic().id
      this.getConfigs(clinicId).then(() => {
        this.addDays()
      })
    }
    else {
      this.addDays()
    }
  },

  methods: {
    ...mapActions('sellingPanel', ['getConfigs']),
    addDays() {
      const expireTimes = Object.values(this.configs.EXPIRE_TIME_IN_DAYS)
      this.expireDays = this.configs.EXPIRE_TIME_TO_CONSIDER === 'GREATER' ? Math.max(...expireTimes) : Math.min(...expireTimes)
      this.addHolidayGaps()

      const date = new Date();
      date.setDate(date.getDate() + this.expireDays);
      this.expireTime = date
      if(! this.value.expires_at) {
        this.value.expires_at = this.moment(date).format('YYYY-MM-DD HH:mm:ss')
      }
    },

    addHolidayGaps() {
      let i = 0;
      const d = this.moment(new Date())
      while(i <= this.expireDays) {
        if([0,6].includes(d.day()) || holidays.MD[d.format('MM/DD')]) {
          this.expireDays++
        }
        d.add(1,'day')
        i++
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>